const { DirectUpload } = require('@rails/activestorage')

class ImageFormField {

    static MAX_IMAGE_SIZE_MB = 8;
    static MAX_IMAGE_WIDTH = 10000;
    static MAX_IMAGE_HEIGHT = 10000;

    static setup() {
        ImageFormField.uploadsInProgress = 0
        let $imagesFormFieldItems = $('.ImagesFormField__Items')
        $imagesFormFieldItems.on('click', '.ImageFormField__remove', ImageFormField.removeImageFormField)
        $imagesFormFieldItems.sortable()
    }

    static handleImageFormFieldChange(el, e) {
        let input = e.currentTarget
        var $currentImageFormField = $(input).parents('.ImageFormField')

        if (input.files && input.files.length > 0) {
            if ($currentImageFormField.hasClass('ImageFormField--new')) {
                $newImageFormField = $currentImageFormField.clone()
                $newImageFormField.find('input[type=file]').val(null)
                $newImageFormField.insertAfter($currentImageFormField)
                $currentImageFormField.appendTo('.ImagesFormField__Items')
                $currentImageFormField
                    .removeClass('ImageFormField--new')
                    .removeClass('ImageFormField--empty')
            }

            for (var file of input.files) {
                if (file != input.files[0]) {
                    $currentImageFormField = $currentImageFormField
                        .clone()
                        .insertAfter($currentImageFormField)
                }

                ImageFormField.processFile(file, $currentImageFormField, true)
            }
        }
        $('.ImagesFormField__Items').sortable('refresh')
    }

    static processFile(file, $imageFormField, isDirectUpload) {
        //reset image errors
        $('.ImageFormField__Error').text('')

        //validate filesize
        if (file.size > ImageFormField.MAX_IMAGE_SIZE_MB * 1024 * 1024){
            if (isDirectUpload){
                $imageFormField.remove()
            }
            const error_text = `Fehler: Das Foto ist mit ${(file.size/1024/1024).toFixed(1)}MB zu groß. Maximale Größe ${this.MAX_IMAGE_SIZE_MB}MB.`
            ImageFormField.setImageValidationError(error_text)
            return;
        }

        //load image and validate resolution
        const img = new Image();
        const url = URL.createObjectURL(file); // Create a temporary object URL

        img.onload = function () {
            // Validate image resolution
            if (img.width > ImageFormField.MAX_IMAGE_WIDTH || img.height > ImageFormField.MAX_IMAGE_HEIGHT) {
                URL.revokeObjectURL(url); // Clean up the object URL
                if (isDirectUpload){
                    $imageFormField.remove()
                }
                const error_text = `Fehler: Die Bildauflösung ist mit ${img.width}x${img.height}px zu groß. \nMaximal Auflösung:  ${ImageFormField.MAX_IMAGE_WIDTH}x${ImageFormField.MAX_IMAGE_HEIGHT}px.`;
                ImageFormField.setImageValidationError(error_text)
                return;
            }

            // If valid, set the background image
            $imageFormField.css(
                'background-image',
                'url(' + url + ')'
            );

            // Upload Image using direct upload.
            if (isDirectUpload) {
                ImageFormField.uploadFile(file, $imageFormField)
            } else {
                $imageFormField.removeClass('ImageFormField--empty')
            }
        };

        img.onerror = function () {
            ImageFormField.setImageValidationError("Ungültige Bilddatei.")
            URL.revokeObjectURL(url); // Clean up the object URL
        };

        // Set the source of the image to trigger the load
        img.src = url;

    }

    static uploadFile(file, $imageFormField) {
        console.log(`ImageFormField: Uploading ${file.name}`)
        let $imageFormFieldStatus = $imageFormField.find(
            '.ImageFormField__Status'
        )
        let input = $imageFormField.find('input[type=file]')[0]
        ImageFormField.uploadsInProgress++

        $imageFormField.parents('form').attr('disabled', true)

        const url = input.dataset.directUploadUrl
        const upload = new DirectUpload(file, url, {
            directUploadWillStoreFileWithXHR(request) {
                request.upload.addEventListener('progress', (event) => {
                    $imageFormFieldStatus.text(
                        `Fortschritt: ${(
                            (event.loaded / event.total) *
                            100
                        ).toFixed()}%`
                    )
                })
            },
        })

        upload.create((error, blob) => {
            $(input).val(null)

            if (error) {
                ImageFormField.uploadsInProgress--
                $imageFormFieldStatus.text('Fehler: ' + error)
            } else {
                ImageFormField.uploadsInProgress--
                $imageFormFieldStatus.text('Hochgeladen!')
                $imageFormField
                    .find('.ImageFormField__BlobIdField')
                    .val(blob.signed_id)
            }

            if (ImageFormField.uploadsInProgress === 0) {
                $imageFormField.parents('form').attr('disabled', false)
            }
        })
    }

    static removeImageFormField(e) {
        e.preventDefault()
        e.stopImmediatePropagation()
        $(e.currentTarget).parents('.ImageFormField').remove()
    }

    static setImageValidationError(error_text){
        $('.ImageFormField__Error').last().text(error_text)
    }
}

window.handleImageFormFieldChange = function (el, e) {
    ImageFormField.handleImageFormFieldChange(el, e)
}

window.updateImageFormFieldPreview = function (el, e) {
    let input = e.currentTarget
    let $imageFormField = $(input).parents('.ImageFormField')

    if (input.files && input.files[0]) {
        ImageFormField.processFile(input.files[0], $imageFormField, false)
    }
}

$(document).ready(ImageFormField.setup)
$(document).on('remote-content-loaded', ImageFormField.setup)
